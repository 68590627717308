@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: Inter;
        font-weight: 400;
        src: url('../fonts/Inter-Regular.ttf') format("truetype");
      }
      @font-face {
        font-family: Inter;
        font-weight: 500;
        src: url('../fonts//Inter-Medium.ttf') format("truetype");
      }
    
      @font-face {
        font-family: Inter;
        font-weight: 600;
        src: url('../fonts//Inter-SemiBold.ttf') format("truetype");
      }

      @font-face {
        font-family: Inter;
        font-weight: 700;
        src: url('../fonts//Inter-Bold.ttf') format("truetype");
      }

      @font-face {
        font-family: Inter;
        font-weight: 800;
        src: url('../fonts//Inter-ExtraBold.ttf') format("truetype");
      }

      @font-face {
        font-family: Inter;
        font-weight: 900;
        src: url('../fonts//Inter-Black.ttf') format("truetype");
      }
}

@layer components {

    body,
    html {
        @apply w-full overflow-x-hidden font-primary
    }
    
    a {
        @apply outline-none
    }

    .loader {
        @apply border-4 border-light-gray border-t-4 border-t-dark-gray rounded-full w-[120px] h-[120px] animate-spin
    }

    .section-title {
        @apply text-xl md:text-[40px] font-black leading-[1.4] mb-5 text-dark-gray text-center
    }

    .section-title.white {
        @apply text-white
    }

    .section-desc {
        @apply text-[10px] md:text-xl text-light-gray leading-[1.4] font-medium text-center
    }

    .section-desc.white {
        @apply text-white
    }

    .bg-third {
        @apply bg-[#009]
    }

    .header {
        @apply absolute w-full top-0 flex justify-between items-center h-[60px] lg:h-[72px] flex-wrap z-50
    }

    .header.primary {
        @apply bg-white border-b border-b-light-gray/30
    }

    .header.secondary {
        @apply bg-transparent
    }

    .header .loader {
        @apply absolute top-full w-full
    }

    .header .loader .progress-bar {
        @apply left-0 top-0 bottom-0 right-0 !absolute
    }

    .header>div:first-child {
        @apply flex h-full w-full
    }

    .nav-bar-btn {
        @apply block lg:hidden relative overflow-hidden w-16 px-4 z-50 h-full
    }

    .nav-bar-btn span {
        @apply h-0.5 w-full relative bg-black block before:content-[''] before:transition-transform before:duration-300 after:transition-transform after:duration-300 before:h-0.5 before:w-full before:bg-black before:-top-2 before:absolute before:left-0 after:content-[''] after:h-0.5 after:w-full after:bg-black after:-bottom-2 after:absolute after:left-0
    }

    .nav-bar-btn span.open {
        @apply !bg-transparent before:!top-0 before:rotate-45 after:!bottom-0 after:-rotate-45
    }

    .header__logo {
        @apply flex lg:left-7 lg:translate-x-0 w-[120px] items-center lg:h-auto absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-50
    }

    .header__logo img {
        @apply w-full h-full object-cover
    }

    .nav__menu {
        @apply flex -translate-y-full lg:items-center absolute lg:relative left-0 duration-[400ms] transition-all lg:pt-0 lg:top-0 lg:bg-transparent lg:overflow-hidden lg:translate-y-0 top-[59px] w-full pt-[34px] z-40 bg-white overflow-y-auto overflow-x-hidden lg:mx-40 h-[calc(100vh-61px)] lg:h-full max-w-[1400px] min-[1650px]:mx-auto min-[1650px]:px-[50px]
    }

    .nav__menu.show {
        @apply translate-y-[1px]
    }

    .nav__menu>ul {
        @apply lg:flex block lg:items-center m-0 p-0 list-none w-full gap-x-5
    }

    .nav__menu>ul>li {
        @apply p-0
    }

    .nav__menu>ul>li a {
        @apply uppercase lg:py-6 py-4 pr-5 pl-10 font-semibold lg:px-0 relative duration-300 text-sm flex w-full lg:inline-block min-h-[71px] items-center
    }

    .header.primary .nav__menu>ul>li a {
        @apply border-b border-b-light-gray/30 lg:border-none text-light-gray
    }

    .header.primary.pixad .nav__menu>ul>li a {
        @apply lg:hover:text-pixad-primary
    }

    .header.secondary .nav__menu>ul>li a {
        @apply text-white
    }

    .header.primary.pixad .nav__menu>ul>li a.active {
        @apply text-pixad-primary
    }

    .header.primary.admatic .nav__menu>ul>li a {
        @apply lg:hover:text-admatic-primary
    }

    .header.primary.admatic .nav__menu>ul>li a.active {
        @apply text-admatic-primary
    }

    .sub__nav {
        @apply flex z-50 absolute right-8
    }

    .sub__nav-actions {
        @apply hidden lg:flex items-center gap-x-2.5 px-2.5
    }

    .action-button-border {
        @apply hidden border-primary hover:bg-primary hover:text-white hover:border-primary transition-colors duration-300 leading-7 text-xs min-h-[30px] px-4 font-semibold rounded min-w-[40px] xl:block text-primary border
    }

    .action-button-fill {
        @apply text-white transition-colors duration-300 leading-7 text-xs min-h-[30px] px-4 font-semibold rounded min-w-[40px] block border
    }

    .action-button-fill.pixad.primary {
        @apply border-pixad-primary bg-pixad-primary hover:border-pixad-primary
    }

    .action-button-fill.admatic.primary {
        @apply border-admatic-primary bg-admatic-primary hover:border-admatic-primary
    }

    .action-button-fill.pixad.secondary {
        @apply border-pixad-secondary bg-pixad-secondary hover:border-pixad-secondary
    }

    .action-button-fill.admatic.secondary {
        @apply border-admatic-secondary bg-admatic-secondary hover:border-admatic-secondary
    }

    .action-button-normal {
        @apply px-3 block text-xs min-h-[30px] leading-7 hover:bg-[#95b3be29] rounded min-w-[40px] font-semibold transition-colors duration-300
    }

    .action-button-normal.primary {
        @apply text-dark-gray
    }

    .action-button-normal.secondary {
        @apply text-white
    }

    .hero__section {
        @apply relative w-full flex flex-col max-w-[1400px] mt-[72px] pt-5 md:pt-[120px] max-h-[746px] mx-auto px-5 sm:px-[50px] after:absolute after:content-[''] after:bg-no-repeat after:top-0 after:right-0 after:bg-[length:100%] after:h-full after:bg-[right_-25px_top] after:w-[310px] min-[375px]:after:w-[375px] min-[425px]:after:w-[425px] min-[500px]:after:w-[500px] sm:after:w-[640px] md:after:w-[750px] md:after:bg-[right_-50px_top_95px] lg:after:w-[1200px] lg:after:bg-[right_-95px_top_-20px] min-[1440px]:after:w-[1600px] min-[1440px]:after:bg-[right_-100px_top]
    }

    .hero__section.pixad {
        @apply after:bg-pixad-home-hero
    }

    .hero__section.admatic {
        @apply after:bg-admatic-home-hero
    }

    .hero__section>div:first-child {
        @apply mb-[42px] md:mb-0 md:pb-36 z-10 justify-center h-full w-full flex flex-col
    }

    .hero__section .hero-buttons {
        @apply flex w-full gap-x-5 justify-center md:justify-start
    }

    .hero__section .hero__title {
        @apply text-dark-gray
    }

    .hero__title {
        @apply font-black leading-[1.2] mb-16 text-[20px] min-[375px]:text-[25px] min-[425px]:text-[30px] min-[500px]:text-[35px] sm:text-[48px] lg:text-[61px] max-w-[180px] sm:max-w-[300px]
    }

    .button-primary {
        @apply px-7 text-white font-medium rounded text-xs min-h-[40px] sm:min-h-[52px] min-w-[40px] w-auto whitespace-nowrap leading-[40px] sm:leading-[52px] sm:tracking-[1.4px] transition-colors duration-300 hover:text-dark-gray border-2 inline-block
    }

    .button-primary.pixad {
        @apply bg-pixad-primary border-pixad-primary hover:bg-transparent
    }

    .button-primary.admatic {
        @apply bg-admatic-primary border-admatic-primary hover:bg-transparent
    }

    .button-primary span, .button-secondary span {
        @apply flex items-center justify-center
    }

    .button-secondary {
        @apply px-7 font-medium rounded text-xs min-h-[40px] sm:min-h-[52px] min-w-[40px] w-auto whitespace-nowrap leading-[40px] sm:leading-[52px] sm:tracking-[1.4px] transition-colors duration-300 border-2 inline-block
    }

    .button-secondary.pixad {
        @apply bg-pixad-secondary text-dark-gray border-pixad-secondary hover:bg-transparent
    }

    .button-secondary.admatic {
        @apply bg-admatic-secondary text-white border-admatic-secondary hover:bg-transparent hover:text-dark-gray
    }

    .scroll__button {
        @apply md:absolute static cursor-pointer md:left-1/2 md:-translate-x-1/2 md:bottom-10 z-10
    }

    .scroll__button a {
        @apply min-w-[32px] relative text-sm leading-9 inline-block overflow-hidden hover:text-white hover:before:bg-secondary hover:before:-z-10 text-secondary before:absolute before:content-[''] before:left-0 before:right-0 before:top-0 before:w-full before:h-8 before:rounded-full before:transition-all before:duration-300 before:bg-transparent
    }

    .scroll__button a svg {
        @apply transition-all duration-300 align-middle
    }

    .sub__nav-lang {
        @apply border-l border-l-[#d1d1d4] px-2.5 py-1.5 relative min-w-[50px]
    }

    .sub__nav-lang a {
        @apply text-[#333] text-xs font-semibold block hover:bg-[#95b3be29] transition-colors duration-300 rounded-full leading-7 text-center
    }

    .vision-section {
        @apply relative w-full flex py-20 min-[1900px]:pb-96 min-[2200px]:pb-[500px] min-h-[600px] md:min-h-[700px] bg-[#fff] bg-vision bg-cover bg-no-repeat bg-[center_top_5rem]
    }

    .vision-section .wrap {
        @apply max-w-[1222px] mx-auto flex flex-col items-center justify-center px-10 h-full
    }

    .vision-section>div a {
        @apply absolute left-1/2 -translate-x-1/2 bottom-40
    }

    .vision-section>div>div:first-child {
        @apply max-w-[680px] z-10 text-center md:max-w-[1100px]
    }

    .latest-news-section {
        @apply relative w-full md:pt-[70px] pt-[55px] md:pb-[80px]
    }

    .latest-news-section.pixad {
        @apply bg-pixad-primary
    }

    .latest-news-section.admatic {
        @apply bg-admatic-secondary
    }

    .latest-news-section .wrap {
        @apply px-[15px] md:px-[30px]
    }

    .latest-news-section>div>div:first-child {
        @apply mb-[60px] max-w-[700px] mx-auto text-center
    }

    .latest-news-section>div>div:last-child {
        @apply relative w-[300px] min-[375px]:w-[350px] min-[425px]:w-[400px] sm:w-[640px] md:w-[768px] lg:w-auto mx-auto
    }

    .latest-news-section .slider-wrapper {
        @apply relative
    }

    .latest-news-section .slider-wrapper img {
        @apply w-full
    }

    .latest-news-section .slider-wrapper .text-wrapper {
        @apply absolute z-10 left-1/2 top-0 pt-4 sm:pt-10 w-[125px] min-[375px]:w-[150px] min-[425px]:w-[180px] sm:w-[280px] md:pl-10 lg:pl-16 lg:pt-16 md:w-[350px] lg:w-[400px] h-full
    }

    .latest-news-section .slider-wrapper .text-wrapper h3 {
        @apply font-extrabold text-dark-gray mb-1 sm:mb-2 md:text-2xl sm:text-xl min-[425px]:text-base min-[425px]:!leading-5 sm:!leading-6 lg:!leading-8 min-[375px]:text-sm min-[375px]:leading-[18px] text-[10px] leading-[14px]
    }

    .latest-news-section .slider-wrapper .text-wrapper p {
        @apply text-dark-gray md:text-sm font-medium md:mb-10 sm:text-xs sm:mb-8 mb-1 min-[425px]:text-[8px] text-[6px]
    }

    .latest-news-section .slider-wrapper .text-wrapper a {
        @apply md:min-h-[52px] md:leading-[52px] sm:text-xs sm:min-h-[40px] sm:leading-[40px] min-[375px]:min-h-[24px] min-[375px]:leading-[24px] min-[375px]:text-[10px] text-[8px] min-h-[20px] leading-5
    }

    .latest-news-section .latest-slider-dots {
        @apply absolute bottom-5 right-[20%] lg:right-5 z-10 gap-x-5 flex
    }

    .latest-news-section .latest-slider-dots button {
        @apply min-w-[48px] min-h-[48px] text-white hover:bg-light-gray rounded-full flex items-center justify-center transition-colors duration-300
    }

    /* EVENTS */

    .events-section {
        @apply relative w-full md:pt-[70px] pt-[55px] md:pb-[80px] bg-white md:bg-light-gray
    }

    .events-section>div {
        @apply max-w-[1190px] mx-auto md:px-10
    }

    .events-section>div>div:first-child {
        @apply text-center mb-[60px]
    }

    .events-section>div>div:first-child h2 {
        @apply text-[#333] mb-[30px] text-center relative inline-block text-[38px] leading-[52px] font-bold before:content-[''] before:left-0 before:right-0 sm:before:-left-[30px] sm:before:-right-[30px] before:absolute before:bottom-0 before:h-[22px] before:bg-[#39f]
    }

    .events-section>div>div:first-child h2 span {
        @apply z-10 relative
    }

    .events-section>div>div:first-child p {
        @apply text-[#333] max-w-[600px] mx-auto text-lg leading-normal
    }

    .events-section>div>div:last-child {
        @apply relative
    }

    .slider-wrapper__right {
        @apply lg:justify-start justify-center flex-shrink-0 h-full relative !flex
    }

    .slider-wrapper__right>div {
        @apply relative pb-[90px] md:py-[50px] pl-0 flex-col lg:flex-row lg:pl-20 lg:py-5 bg-[#39f] flex justify-start items-center max-w-[325px] sm:max-w-[375px] md:w-[832px] h-auto lg:h-[500px] box-border md:max-w-[600px] lg:max-w-full
    }

    .slider-wrapper__right>div>div {
        @apply max-w-[380px]
    }

    .slider-wrapper__right .slider-link {
        @apply max-h-full text-white hover:underline
    }

    .slider-wrapper__right>div div a img {
        @apply lg:absolute static translate-y-0 w-full md:w-[400px] min-[1180px]:w-full z-10 max-w-full min-[1180px]:max-w-[555px] top-1/2 lg:-translate-y-1/2 -right-[100px] min-[1180px]:-right-[277px]
    }

    .slider-wrapper__right>div div a h3 {
        @apply mb-5 text-white font-semibold text-[30px] leading-[1.2] box-border px-[30px] md:mt-0 mt-10 md:px-0
    }

    .slider-wrapper__right>div p {
        @apply text-lg leading-[1.6] pb-10 text-white px-[30px] md:mt-0 mt-10 md:px-0
    }

    .events-section .read-more-button {
        @apply rounded-[26px] border border-white text-white bg-transparent inline-block ml-[30px] md:ml-0 md:hidden px-[30px] text-sm leading-[52px] min-h-[52px] min-w-[40px] lg:inline-block font-semibold tracking-[1.4px] hover:bg-white hover:text-[#009] transition-colors duration-300 whitespace-nowrap
    }

    .coverage-section {
        @apply relative w-full flex
    }

    .coverage-section .wrap {
        @apply max-w-none px-0 m-0 w-full
    }

    .coverage-section .text-wrapper {
        @apply text-center w-full mb-[40px]
    }

    .coverage-section .coverage-bg {
        @apply bg-cover bg-center relative bg-no-repeat w-screen lg:h-[1300px] pt-[62px] pb-[80px] flex flex-col items-center
    }

    .coverage-section.pixad .coverage-bg {
        @apply lg:bg-pixad-numbers
    }

    .coverage-section.admatic .coverage-bg {
        @apply lg:bg-admatic-numbers
    }

    .coverage-section .coverage-bg img {
        @apply h-[320px] min-[425px]:h-[425px] w-full object-cover min-[575px]:h-[575px] md:h-[768px]
    }

    .coverage-section .circles {
        @apply w-full flex flex-col lg:flex-row justify-between items-center max-w-[1250px] min-[2000px]:max-w-[1450px] mt-2.5 lg:mt-32 gap-y-2.5
    }

    .coverage-section .circle {
        @apply w-[150px] h-[150px] md:w-[250px] md:h-[250px] rounded-full border-4 flex justify-center items-center flex-col min-[2000px]:w-[350px] min-[2000px]:h-[350px]
    }

    .coverage-section.pixad .circle {
        @apply border-pixad-secondary
    }

    .coverage-section.admatic .circle {
        @apply border-admatic-primary
    }

    .coverage-section .circle-title {
        @apply text-xl md:text-5xl font-bold mb-1 leading-[1.4] text-center text-dark-gray
    }

    .coverage-section .circle-desc {
        @apply text-xs md:text-xl font-semibold leading-normal text-center text-dark-gray max-w-[200px]
    }

    .coverage-section .coverage-footer {
        @apply mt-2.5 lg:mt-auto flex flex-col items-center gap-y-5
    }

    .coverage-section a {
        @apply leading-[40px] min-h-[40px]
    }

    .coverage-section .coverage-footer>* {
        @apply m-0
    }

    .partners-section {
        @apply relative w-full flex py-[70px] bg-[#f5f9f9]
    }

    .partners-section>div {
        @apply max-w-[1046px] mx-auto w-full px-10
    }

    .partners-section h2 {
        @apply text-center mb-2.5 text-[38px] font-semibold leading-[1.2]
    }

    .partners-section p {
        @apply text-center text-xl mb-[70px] leading-normal
    }

    .partners-section>div>div {
        @apply flex justify-center md:justify-start flex-wrap -mx-0.5 mb-[54px]
    }

    .partners-section .main-partners-images {
        @apply w-[calc(100%/2-16px)] sm:w-[calc(100%/3-16px)] md:w-[calc(100%/7-4px)] overflow-hidden rounded-full mx-2 md:mx-0.5 mb-4 bg-white
    }

    .partners-section .main-partners-images img {
        @apply w-full h-full
    }

    .main-partners-images:nth-of-type(2),
    .main-partners-images:nth-of-type(4),
    .main-partners-images:nth-of-type(6),
    .main-partners-images:nth-of-type(9),
    .main-partners-images:nth-of-type(11),
    .main-partners-images:nth-of-type(13),
    .main-partners-images:nth-of-type(16),
    .main-partners-images:nth-of-type(18),
    .main-partners-images:nth-of-type(20) {
        @apply md:translate-y-1/2
    }

    .cooperate-section {
        @apply relative w-full flex py-14
    }

    .cooperate-section.pixad.primary {
        @apply bg-pixad-primary
    }

    .cooperate-section.pixad.secondary {
        @apply bg-pixad-secondary
    }

    .cooperate-section.admatic.primary {
        @apply bg-admatic-primary
    }

    .cooperate-section.admatic.secondary {
        @apply bg-admatic-secondary
    }

    .cooperate-section .wrap div {
        @apply w-full text-center
    }

    .cooperate-section a {
        @apply text-black bg-white leading-[40px] min-h-[40px] hover:text-white border-none
    }

    .cooperate-section.pixad.primary a {
        @apply hover:!bg-pixad-secondary
    }

    .cooperate-section.pixad.secondary a {
        @apply hover:!bg-pixad-primary
    }

    .cooperate-section.admatic.primary a {
        @apply hover:!bg-admatic-secondary
    }

    .cooperate-section.admatic.secondary a {
        @apply hover:!bg-admatic-primary
    }

    .footer {
        @apply mt-10 bg-white overflow-hidden
    }

    .footer>div {
        @apply lg:px-10 px-[30px] mx-auto max-w-[1190px]
    }

    .footer .top-footer {
        @apply flex flex-wrap
    }

    .footer-breadcrumbs {
        @apply w-full hidden lg:block
    }

    .footer-breadcrumbs ul {
        @apply text-sm leading-7 border-b border-b-[#d1d1d4] py-[11px] mb-[57px] list-none
    }

    .footer-breadcrumbs ul li {
        @apply inline-block align-middle
    }

    .footer-breadcrumbs ul li>* {
        @apply inline-block align-middle capitalize text-light-gray
    }

    .footer-breadcrumbs ul li span {
        @apply before:content-['→'] before:font-bold before:text-light-gray before:mx-4
    }

    .footer-breadcrumbs ul li:first-child a {
        @apply text-dark-gray
    }

    .footer-nav {
        @apply w-full lg:w-[calc(100%-350px)] order-2 lg:order-1
    }

    .footer-nav .mobile-nav {
        @apply mb-[41px] text-sm font-semibold leading-[1.3571]
    }

    .footer-nav .mobile-nav ul {
        @apply flex flex-col
    }

    .footer-nav .mobile-nav ul li {
        @apply border-b border-b-light-gray w-full relative overflow-hidden h-14 flex items-center
    }

    .footer-nav .mobile-nav ul li a {
        @apply text-light-gray inline-block text-sm rounded py-1.5 leading-8 h-9 w-full
    }

    .footer-nav .navmenu {
        @apply pb-20 text-sm font-semibold leading-[1.3571]
    }

    .footer-nav .navmenu>ul {
        @apply flex list-none m-0 p-0
    }

    .footer-nav .navmenu>ul>li {
        @apply w-1/4 overflow-hidden relative
    }

    .footer-nav .navmenu>ul>li>div {
        @apply w-full
    }

    .footer-nav .navmenu>ul>li>div div {
        @apply flex items-center w-full
    }

    .footer-nav .navmenu>ul>li>div div span {
        @apply pt-1 px-1.5 rounded text-sm text-dark-gray leading-8 w-full
    }

    .footer-nav .navmenu>ul>li>div ul {
        @apply mt-5 ml-0.5 font-normal list-none p-0
    }

    .footer-nav .navmenu>ul>li>div ul li a {
        @apply relative inline-block py-[7px] px-1 text-light-gray no-underline before:opacity-0 before:absolute before:bottom-0 before:left-0 before:w-full before:h-[1px] before:content-[''] before:transition-opacity before:duration-200 hover:before:opacity-100
    }

    .footer.pixad .footer-nav .navmenu>ul>li>div ul li a {
        @apply before:bg-pixad-secondary
    }

    .footer.admatic .footer-nav .navmenu>ul>li>div ul li a {
        @apply before:bg-admatic-primary
    }

    .upcoming-event {
        @apply lg:w-[350px] lg:mb-9 mb-[31px] order-1 lg:order-2
    }

    .upcoming-event>div {
        @apply lg:ml-16 pt-[30px] pb-[26px] lg:pt-[13px] lg:pb-2.5 pl-[30px] border-l border-l-light-gray text-sm font-semibold
    }

    .upcoming-event>div a {
        @apply text-light-gray hover:underline
    }

    .upcoming-event .section-title {
        @apply w-full mb-4 text-light-gray pb-1.5 border-b border-b-light-gray inline-block
    }

    .upcoming-event .event-title {
        @apply leading-[1.36]
    }

    .upcoming-event .event-desc {
        @apply font-normal leading-[1.43] mb-[22px]
    }

    .upcoming-event>div a>div:last-child {
        @apply relative
    }

    .upcoming-event>div a>div:last-child div {
        @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[120px] w-full z-10 h-auto
    }

    .upcoming-event>div a img {
        @apply w-full h-full object-cover
    }

    .bottom-footer>div:first-child {
        @apply flex flex-col gap-y-10 lg:gap-y-0 lg:flex-row items-center justify-center mb-[38px] lg:mx-0 -mr-10 -ml-10
    }

    .bottom-footer>div:first-child ul {
        @apply list-none flex justify-center w-full mb-10 px-2.5 py-8 bg-white lg:bg-transparent lg:w-auto lg:justify-start items-center lg:p-0 lg:m-0
    }

    .bottom-footer>div:first-child ul li {
        @apply mr-1.5
    }

    .bottom-footer>div:first-child ul li a {
        @apply text-light-gray hover:bg-dark-gray inline-block text-center hover:text-white w-8 h-8 leading-8 tracking-normal rounded-full p-0 min-w-0 transition-colors duration-200 font-semibold
    }

    .bottom-footer>div:first-child ul li a span {
        @apply flex static items-center justify-center h-full
    }

    .bottom-footer>div:first-child ul li a svg {
        @apply flex align-middle leading-8
    }

    .bottom-footer .footer-actions {
        @apply flex items-center gap-x-[15px] text-[#333]
    }

    .bottom-footer>div:last-child {
        @apply w-full border-t border-t-[#d1d1d4] pt-8 pb-[46px] flex flex-wrap
    }

    .bottom-footer>div:last-child>div:first-child {
        @apply w-full lg:w-[51%] pt-4
    }

    .bottom-footer>div:last-child>div:first-child ul {
        @apply m-0 p-0 list-none flex flex-wrap justify-center lg:justify-start text-xs leading-4
    }

    .bottom-footer>div:last-child>div:first-child ul li:first-child {
        @apply text-[#577e8f] lg:mb-2.5 lg:mr-5 lg:order-none lg:w-auto order-1 w-full text-center lg:mt-0 mt-5 mb-px
    }

    .bottom-footer>div:last-child>div:first-child ul li {
        @apply mb-2.5 mx-[17px] lg:ml-0 lg:mr-5
    }

    .bottom-footer>div:last-child>div:first-child ul li a {
        @apply text-[#333]
    }

    .bottom-footer>div:last-child>div:last-child {
        @apply w-full lg:w-[49%]
    }

    .bottom-footer>div:last-child>div:last-child ul {
        @apply m-0 p-0 list-none flex flex-wrap justify-end
    }

    .bottom-footer>div:last-child>div:last-child ul li {
        @apply mb-[15px] mr-[15px]
    }


    /* CONTACT */

    .contact-header {
        @apply relative pt-[142px] pb-[120px] lg:pt-[130px] lg:pb-[216px] mt-[60px] lg:mt-[72px]
    }

    .contact-header .contact-header-bg {
        @apply absolute top-0 left-0 right-0 bottom-0 z-[-1]
    }

    .contact-header .contact-header-bg img {
        @apply w-full h-full object-cover
    }

    .contact-header-wrapper {
        @apply px-[30px]
    }

    .contact-header-wrapper .contact-title {
        @apply text-[38px] font-semibold text-center
    }

    .contact-header-wrapper .contact-title.pixad {
        @apply text-pixad-secondary
    }

    .contact-header-wrapper .contact-title.admatic {
        @apply text-admatic-primary
    }

    .contact-header-wrapper .contact-desc {
        @apply text-xl text-white leading-[1.4] mt-2.5 text-center
    }

    .contact-content {
        @apply z-10 relative flex flex-wrap mx-auto justify-center w-full
    }

    .contact-col {
        @apply w-full m-0 lg:w-1/2 lg:-mt-[148px] lg:max-w-[490px] lg:mb-[114px];
        box-shadow: 0 6px 6px -3px rgba(0, 0, 0, .06), 0 10px 14px 1px rgba(0, 0, 0, .042), 0 4px 18px 3px rgba(0, 0, 0, .036);
    }

    .contact-form {
        @apply py-[50px] px-[30px] bg-white max-w-[490px] mx-auto
    }

    .contact-form h2 {
        @apply text-2xl font-semibold text-[#333]
    }

    .contact-form form {
        @apply mt-12
    }

    .contact-form form .form-group {
        @apply mb-4 flex flex-col
    }

    .contact-form form label {
        @apply block text-[10px] font-semibold leading-[1.4] tracking-[0.5px] mb-1.5 uppercase text-[#333]
    }

    .contact-form form .field-wrapper {
        @apply border w-full leading-[1.125] text-[#d1d1d4] border-[#d1d1d4] pb-3 pt-[9px] px-3 relative
    }

    .contact-form form .field-wrapper>* {
        @apply outline-none w-full resize-none text-black
    }

    .contact-form form .button-primary {
        @apply disabled:bg-light-gray disabled:border-light-gray disabled:hover:text-white
    }

    .contact-form .field-wrapper>.select-placeholder {
        @apply text-light-gray absolute left-3 top-1/2 -translate-y-1/2 pointer-events-none
    }

    .w-checkbox>label {
        @apply !flex items-center w-full
    }

    .w-checkbox label span:first-child {
        @apply w-5 h-5 inline-block
    }

    .w-checkbox label span:first-child input {
        @apply w-full h-full
    }

    .w-checkbox label span:last-child {
        @apply text-xs leading-[1.33] normal-case tracking-normal font-normal text-left inline-block h-full ml-1
    }

    .contact-info-wrapper.pixad {
        @apply bg-pixad-primary
    }

    .contact-info-wrapper.admatic {
        @apply bg-admatic-secondary
    }

    .contact-info-wrapper>div {
        @apply max-w-[490px] h-full px-[34px] pb-14 pt-[50px] mx-auto flex flex-col justify-between
    }

    .contact-info-wrapper .location-content {
        @apply mb-[70px]
    }

    .contact-info-wrapper .location-content>h3 {
        @apply text-2xl font-semibold leading-[1.3] text-white mb-[14px]
    }

    .contact-info-wrapper .location-content>div {
        @apply mt-[50px]
    }

    .contact-info-wrapper .location-content .infos-title {
        @apply text-lg font-semibold leading-normal text-left text-white mb-[25px]
    }

    .contact-info-wrapper .location-content .info-text {
        @apply text-base text-white leading-normal m-0 text-left
    }

    .contact-info-wrapper .location-content .info-link {
        @apply flex mt-[30px]
    }

    .contact-info-wrapper.pixad .location-content .info-link {
        @apply text-pixad-secondary
    }

    .contact-info-wrapper.admatic .location-content .info-link {
        @apply text-admatic-primary
    }

    .contact-info-wrapper .location-content .info-link span {
        @apply text-xs leading-[1.3] tracking-[0.6px] ml-2.5 uppercase align-middle inline-block font-semibold
    }

    .contact-info-wrapper .social-links {
        @apply max-w-[190px]
    }

    .contact-info-wrapper .social-links ul {
        @apply flex gap-x-4
    }

    .contact-info-wrapper .social-links ul li a {
        @apply text-white transition-colors duration-300 hover:bg-white hover:text-[#0f4b81] rounded-full w-8 h-8 leading-8 flex items-center justify-center
    }

    /* InPage */

    .in-page__hero {
        @apply relative min-h-[480px]
    }

    .in-page__hero .hero__title {
        @apply text-white z-10
    }

    .in-page__hero>div {
        @apply block relative h-full w-full min-h-[480px]
    }

    .page-bg {
        @apply flex flex-col mx-auto px-[50px] justify-center min-h-screen w-full after:bg-[75%] after:bg-no-repeat after:bg-cover max-w-[1400px] after:mx-auto after:content-[''] after:w-full after:h-full after:absolute after:left-0 after:top-0
    }

    .page-bg .section-desc {
        @apply z-10 text-left max-w-[425px] break-words
    }

    .page-bg .bg-header-title {
        @apply mt-auto max-w-[980px] text-center pt-[70px] px-[30px] z-10
    }

    .page-bg .bg-header-title p.title-wrapper {
        @apply text-white text-sm leading-[1.36] relative uppercase font-semibold pb-[30px] inline-block before:absolute before:bottom-5 before:h-[18px] before:bg-pixad-secondary before:opacity-75 before:left-[-30px] before:right-[-30px]
    }

    .page-bg .bg-header-title span {
        @apply relative
    }

    .page-bg .bg-header-title h1 {
        @apply mb-[120px] md:mb-[15px] leading-[1.32] text-[38px] md:text-5xl font-semibold text-white
    }

    .page-bg .bg-header-title p:last-of-type {
        @apply md:mb-[90px] leading-[1.28] md:text-2xl text-white tracking-[0.6px] px-[30px] md:px-0 m-0 text-xl
    }

    .page-bg .bg-header-title a {
        @apply bg-pixad-secondary text-white rounded-[26px] text-sm leading-[52px] tracking-[1.4px] min-h-[52px] px-[30px] whitespace-nowrap font-semibold min-w-[40px] hidden md:inline-block uppercase transition-colors duration-300 hover:bg-[#ff2400]
    }

    .platform-description-section {
        @apply relative w-full flex py-20 min-[1900px]:pb-96 min-[2200px]:pb-[500px] min-h-[600px] md:min-h-[700px] bg-[#fff] bg-cover bg-no-repeat bg-[center_top]
    }

    .platform-description-section .wrap {
        @apply max-w-[1222px] mx-auto flex flex-col items-center justify-center px-10 h-full
    }

    .platform-description-section>div a {
        @apply absolute left-1/2 -translate-x-1/2 bottom-40
    }

    .platform-description-section>div>div:first-child {
        @apply max-w-[680px] z-10 text-center md:max-w-[1100px]
    }

    .integration-section {
        @apply py-10 min-h-[520px] min-[350px]:min-h-[500px] relative md:min-h-[750px] lg:min-h-[650px]
    }

    .integration-section img {
        @apply object-cover absolute bottom-0 left-1/2 -translate-x-1/2 -z-10 h-[320px] min-[575px]:h-[450px]
    }

    .models-section {
        @apply py-10 h-[650px] md:h-[1200px] bg-cover bg-center md:bg-[center_top_-80px]
    }
    
    .models-section .wrap {
        @apply flex flex-col justify-between h-full
    }

    .bg-header-nav {
        @apply mt-auto mb-10
    }

    .bg-header-nav nav ul {
        @apply flex
    }

    .bg-header-nav nav .scroll__button a {
        @apply text-white
    }

    .bg-header-nav nav ul li button {
        @apply relative inline-block text-white px-[15px] text-sm font-semibold tracking-[1.4px] leading-9 before:absolute before:content-[''] before:bottom-0 before:left-1/2 before:w-0 before:h-px before:bg-secondary before:-translate-x-1/2 before:transition-all before:duration-200 hover:before:w-full
    }

    .advertisers__platform__section>div {
        @apply flex flex-col
    }

    .advertisers__platform__section>div {
        @apply pt-[68px] after:h-0.5 after:content-[''] after:max-w-[1110px] after:bg-[#e7eff1] after:block after:w-full after:self-center
    }

    .advertisers__platform__section>div>div {
        @apply px-[30px] mx-auto max-w-[1190px] lg:px-10
    }

    .advertisers__platform__section .platform-top {
        @apply mb-20 text-center
    }

    .advertisers__platform__section .platform-top h3 {
        @apply mb-[50px] inline-block mx-auto text-sm leading-[1.36] relative uppercase font-semibold before:content-[''] before:left-[-30px] before:right-[-30px] before:bg-secondary before:opacity-75 before:h-[18px] before:absolute before:-bottom-2 before:-z-10
    }

    .advertisers__platform__section .platform-top h3 span {
        @apply text-[#333]
    }

    .advertisers__platform__section .platform-top p {
        @apply text-[28px] text-secondary font-semibold max-w-[920px] mx-auto
    }

    .advertisers__platform__section .platform-content {
        @apply flex flex-col mb-[64px] lg:flex-row items-center
    }

    .advertisers__platform__section .platform-content .platform-img-wrap {
        @apply w-[268px] lg:w-[336px] relative mx-auto order-2 lg:order-none items-center lg:ml-0 lg:mr-[162px] before:absolute before:content-[''] before:left-0 before:bg-[#0f4b81] before:top-[72px] before:w-[240px] before:h-[480px] before:rounded-s-full before:-z-10 before:-translate-x-[30%] after:absolute after:content-[''] after:right-0 after:bg-secondary after:top-[72px] after:w-[240px] after:h-[480px] after:rounded-e-full after:-z-10 after:translate-x-[30%] before:hidden after:hidden lg:before:block lg:after:block
    }

    .advertisers__platform__section .platform-content .platform-img-wrap img {
        @apply flex h-full w-full
    }

    .advertisers__platform__section .platform-content .platform-desc {
        @apply order-1 mb-[70px] max-w-[446px] text-[#333]
    }

    .advertisers__platform__section .platform-content .platform-desc h2 {
        @apply mb-[30px] text-[32px] font-semibold leading-tight
    }

    .advertisers__platform__section .platform-content .platform-desc p {
        @apply mb-10 text-lg leading-normal
    }

    .publisher-partners-section>div {
        @apply pt-[68px] pb-[80px] lg:pt-[78px]
    }

    .publisher-partners-section>div>div {
        @apply px-[30px] max-w-[1190px] mx-auto
    }

    .publisher-partners-section>div>div p {
        @apply my-10 text-lg lg:text-xl text-center
    }

    .publisher-partners-section .partners-top {
        @apply text-center
    }

    .publisher-partners-section .partners-top h3 {
        @apply mb-2.5 inline-block mx-auto text-sm leading-[1.36] relative uppercase font-semibold before:content-[''] before:left-[-30px] before:right-[-30px] before:bg-pixad-secondary before:opacity-75 before:h-[18px] before:absolute before:-bottom-2 before:-z-10
    }

    .publisher-partners-section .partners-top h3 span {
        @apply text-[#333]
    }

    .publisher-partners-section>div>div>div:last-child {
        @apply flex flex-col justify-center
    }

    .publisher-partners-section .slick-list ul {
        @apply grid grid-cols-2 min-[575px]:grid-cols-3
    }

    .publisher-partners-section ul li {
        @apply flex items-center justify-center px-1.5
    }

    .publisher-partners-section ul li img {
        @apply w-full h-full object-contain grayscale transition-all duration-200 hover:grayscale-0 scale-75
    }

    .publisher-partners-section .partners-wrapper>ul {
        @apply grid grid-cols-6
    }

    .advertisers-reach>div {
        @apply bg-none bg-[50%_center] bg-no-repeat bg-[#0f4b81] pt-[60px] pb-[70px]
    }

    .advertisers-reach>div>div {
        @apply px-[30px] mx-auto max-w-[1190px]
    }

    .advertisers-reach .reach-top {
        @apply text-center mb-[50px]
    }

    .advertisers-reach .reach-top p {
        @apply text-white text-lg leading-normal
    }

    .advertisers-reach .reach-global {
        @apply max-w-[730px] mx-auto mb-[78px] md:mb-[182px] flex flex-col md:flex-row md:justify-between md:items-start justify-center items-center gap-y-[30px]
    }

    .advertisers-reach .reach-global>div {
        @apply w-[315px] h-[315px] flex justify-center flex-col items-center rounded-full gap-1
    }

    .advertisers-reach .reach-global>div p {
        @apply text-white font-semibold text-center
    }

    .advertisers-reach .reach-global>div .global-num {
        @apply leading-[1.4] text-6xl
    }

    .advertisers-reach .reach-global>div .global-text {
        @apply leading-normal text-lg max-w-[160px]
    }

    .advertisers-reach .reach-global>div:first-child {
        @apply md:bg-none md:bg-[#ff6d70b3]
    }

    .advertisers-reach .reach-global>div:last-child {
        @apply md:bg-none md:bg-[#3399ffb3]
    }

    .advertisers-reach ul {
        @apply grid grid-cols-2 gap-y-12 text-white place-items-center md:grid-cols-4
    }

    .advertisers-reach ul li {
        @apply text-center
    }

    .advertisers-reach ul li p:first-child {
        @apply relative mb-4 text-[42px] leading-[1.4] font-semibold z-10 before:absolute before:rounded-lg before:w-[120px] before:h-4 before:left-1/2 before:-translate-x-1/2 before:bottom-[5px] before:-z-10
    }

    .advertisers-reach ul li:first-child p:first-child {
        @apply before:bg-[#39f]
    }

    .advertisers-reach ul li:nth-child(2) p:first-child {
        @apply before:bg-[#778acf]
    }

    .advertisers-reach ul li:nth-child(3) p:first-child {
        @apply before:bg-[#bb7ca0]
    }

    .advertisers-reach ul li:last-child p:first-child {
        @apply before:bg-pixad-secondary
    }

    .advertisers-reach ul li p:last-child {
        @apply max-w-[160px] mx-auto text-base font-semibold leading-[1.3] tracking-[0.4px]
    }

    .why-section>div {
        @apply pt-[70px] md:pt-[90px] bg-white
    }

    .why-section>div>div {
        @apply mx-auto max-w-[1190px] px-[30px]
    }

    .why-section h2 {
        @apply mb-[50px] md:mb-10 text-center text-[32px] md:text-[38px] font-semibold leading-[1.2]
    }

    .why-section img {
        @apply mb-5 md:mb-[70px] w-full
    }

    .why-section ul {
        @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 mb-5 md:mb-[70px]
    }

    .why-section ul svg {
        @apply w-[38px] h-[38px] fill-[#577e8f] mb-5 md:mb-[30px]
    }

    .why-section ul h4 {
        @apply font-semibold text-xl leading-[1.8] mb-5
    }

    .why-section ul p {
        @apply max-w-full text-xl mx-auto leading-[1.4]
    }


    .adv-features-section>div {
        @apply pt-[60px] pb-[70px] md:pt-[90px] md:pb-[100px] bg-[#e7eff1] flex flex-col items-center
    }

    .adv-features-section .landings-title {
        @apply max-w-[920px] mb-[70px]
    }

    .adv-features-section .features-list-wrapper {
        @apply max-w-[315px] min-[640px]:max-w-[640px] md:max-w-[730px] min-[1200px]:max-w-none min-[1200px]:mx-auto block md:flex flex-wrap justify-between
    }

    .features-list-wrapper .adv-features-item {
        @apply pt-[38px] mb-5 !w-[315px] relative h-[256px] px-[30px] pb-2.5 bg-[#fff] box-border md:!w-[350px] md:mb-[30px] md:pt-[46px] before:content-[''] before:w-full before:absolute before:bottom-0 before:left-0 before:right-0 before:h-1
    }

    .features-list-wrapper .adv-features-item.green {
        @apply before:bg-[#1de9b6]
    }

    .features-list-wrapper .adv-features-item.yellow {
        @apply before:bg-[#ffea00]
    }

    .features-list-wrapper .adv-features-item.green svg {
        @apply stroke-[#1de9b6]
    }

    .features-list-wrapper .adv-features-item.yellow svg {
        @apply stroke-[#ffea00]
    }

    .features-list-wrapper .adv-features-item svg {
        @apply fill-none w-[38px] h-[38px] z-10 relative mb-[38px] stroke-2
    }

    .features-list-wrapper .adv-features-item span {
        @apply text-xl font-semibold leading-[1.2]
    }

    .features-list-wrapper .features-list {
        @apply grid-cols-2 grid gap-x-10 min-[1200px]:grid-cols-3
    }

    .adv-steps-section>div {
        @apply pt-[70px] pb-[80px] bg-[#0f4b81]
    }

    .adv-steps-section .landings-title {
        @apply max-w-[920px] mx-auto mb-[70px]
    }

    .adv-steps-section>div ul {
        @apply max-w-[315px] md:max-w-none mx-auto items-start flex justify-between min-[1200px]:justify-center min-[1200px]:items-center flex-wrap list-none min-[1200px]:mx-0
    }

    .adv-steps-section>div ul li.list-image-item {
        @apply min-[1200px]:order-none order-1 mb-2.5 w-full h-auto text-center min-[1200px]:w-[360px] md:mx-[72px]
    }

    .adv-steps-section>div ul li.list-image-item div {
        @apply w-[250px] min-[375px]:w-[300px] mx-auto min-[1200px]:w-full
    }

    .adv-steps-section>div ul li.list-image-item div img {
        @apply w-full h-full object-cover
    }

    .adv-steps-section>div ul li.list-item {
        @apply w-full order-2 min-[1200px]:order-none mb-10 md:w-[30%] min-[1200px]:w-[256px] pt-9 relative
    }

    .adv-steps-section>div ul li.list-item.blue h3 {
        @apply text-[#39f]
    }

    .adv-steps-section>div ul li.list-item.pink h3 {
        @apply text-pixad-secondary
    }

    .adv-steps-section>div ul li.list-item.green h3 {
        @apply text-[#1de9b6]
    }

    .adv-steps-section>div ul li.list-item p.list-text {
        @apply text-left text-lg leading-[1.6] md:text-center mx-auto text-white
    }

    .adv-steps-section>div ul li.list-item h3.list-title {
        @apply text-2xl font-semibold leading-[1.3] text-center mb-5
    }

    /* Publishers Page */

    .pub-ecosystem-section {
        @apply relative after:absolute after:content-[''] after:h-0.5 after:bg-[#e7eff1] after:max-w-[1190px] after:w-full after:bottom-[-70px] after:left-1/2 after:-translate-x-1/2
    }

    .pub-ecosystem-section>div {
        @apply pt-[68px] md:pt-[78px]
    }

    .pub-ecosystem-section>div>div {
        @apply px-[30px] lg:px-0 max-w-[1190px] mx-auto
    }

    .pub-ecosystem-section .pub-ecosystem-top {
        @apply mb-[50px] md:mb-[60px] text-center
    }

    .pub-ecosystem-section .pub-ecosystem-top h2 {
        @apply uppercase mb-[50px] text-sm leading-[1.36] relative font-semibold before:content-[''] before:opacity-75 before:bg-pixad-secondary before:h-[18px] before:left-[-30px] before:right-[-30px] before:block before:-bottom-2.5 before:absolute before:-z-10 inline-block
    }

    .pub-ecosystem-section .pub-ecosystem-top p {
        @apply text-2xl md:text-[32px] text-left md:text-center leading-[1.6] md:leading-[1.4] font-semibold text-[#0f4b81] max-w-[980px] mx-auto
    }

    .pub-ecosystem-section .pub-ecosystem-content {
        @apply max-w-[315px] mx-auto mb-[60px] flex-col justify-center lg:justify-between items-center flex lg:mb-[70px] lg:flex-row lg:max-w-none
    }

    .pub-ecosystem-section .pub-ecosystem-content>div:first-child {
        @apply order-2 lg:order-none text-left lg:text-center w-full max-w-full lg:max-w-[290px] mb-[50px] lg:mb-0
    }

    .pub-ecosystem-section .pub-ecosystem-content>div:nth-child(2) {
        @apply mb-[50px] lg:mb-0 max-w-[400px] order-1 lg:order-none
    }

    .pub-ecosystem-section .pub-ecosystem-content>div:last-child {
        @apply order-3 lg:order-none text-left lg:text-center w-full max-w-full lg:max-w-[300px]
    }

    .pub-ecosystem-section .pub-ecosystem-content .pub-ecosystem-content-img {
        @apply block relative
    }

    .pub-ecosystem-section .pub-ecosystem-content>div:nth-child(2)>div:last-child {
        @apply max-w-[222px] text-center -mt-5 mx-auto
    }

    .pub-ecosystem-section .pub-ecosystem-content>div:nth-child(2)>div:last-child>p:first-child {
        @apply relative mb-4 text-4xl z-10 font-semibold text-center leading-[1.4] before:content-[''] before:w-[120px] before:left-1/2 before:-translate-x-1/2 before:bottom-[5px] before:h-4 before:rounded-lg before:bg-pixad-secondary before:absolute before:-z-10
    }

    .pub-ecosystem-section .pub-ecosystem-content>div:nth-child(2)>div:last-child>p:last-child {
        @apply mx-auto text-base font-semibold leading-[1.3] tracking-[0.4px]
    }

    .pub-ecosystem-section .pub-ecosystem-content .pub-ecosystem-content-img img {
        @apply w-full h-full object-cover inline-block
    }

    .pub-ecosystem-section .pub-ecosystem-content>div h3 {
        @apply pt-[36px] relative text-2xl font-semibold leading-[1.2] mb-5 before:absolute before:content-[''] before:top-0 before:left-0 before:w-4 before:h-4 before:rounded-full before:inline-block lg:before:hidden
    }

    .pub-ecosystem-section .pub-ecosystem-content>div>p {
        @apply uppercase mb-2 text-lg lg:text-xl font-semibold max-w-[200px] lg:max-w-none
    }

    .pub-ecosystem-section .pub-ecosystem-content>div ul {
        @apply p-0 m-0 list-none
    }

    .pub-ecosystem-section .pub-ecosystem-content>div ul li {
        @apply text-lg md:text-xl leading-normal m-0 before:content-["→"] before:text-[#95b3be] before:mr-[15px] before:text-lg lg:before:text-xl before:leading-normal
    }

    .pub-ecosystem-section .pub-ecosystem-content>div:first-child h3 {
        @apply before:bg-pixad-secondary text-pixad-secondary
    }

    .pub-ecosystem-section .pub-ecosystem-content>div:last-child h3 {
        @apply before:bg-[#0f4b81] text-[#0f4b81]
    }

    .our-tech-section>div {
        @apply pt-[70px] pb-[80px]
    }

    .our-tech-section>div ul {
        @apply max-w-[315px] md:max-w-none mx-auto items-start flex justify-between min-[1200px]:justify-center min-[1200px]:items-center flex-wrap list-none min-[1200px]:mx-0
    }

    .our-tech-section>div ul li.list-image-item {
        @apply min-[1200px]:order-none order-1 mb-2.5 w-full h-auto text-center min-[1200px]:w-[360px] md:mx-[72px]
    }

    .our-tech-section>div ul li.list-image-item div {
        @apply w-[250px] min-[375px]:w-[300px] mx-auto min-[1200px]:w-full
    }

    .our-tech-section>div ul li.list-image-item div img {
        @apply w-full h-full object-cover
    }

    .our-tech-section>div ul li.list-item {
        @apply w-full order-2 min-[1200px]:order-none mb-10 md:w-[30%] min-[1200px]:w-[256px] pt-9 before:content-[''] before:absolute before:left-0 before:top-0 before:translate-x-1/2 before:w-4 before:h-4 md:before:left-1/2 md:before:-translate-x-1/2 before:rounded-full relative
    }

    .our-tech-section>div ul li.list-item.blue {
        @apply before:bg-[#0f4b81]
    }

    .our-tech-section>div ul li.list-item.pink {
        @apply before:bg-pixad-secondary
    }

    .our-tech-section>div ul li.list-item.gray {
        @apply before:bg-[#95b3be] md:w-full
    }

    .our-tech-section>div ul li.list-item p.list-text {
        @apply text-left text-lg leading-[1.6] md:text-center mx-auto
    }

    .our-tech-section>div ul li.list-item p.list-text.bold {
        @apply font-semibold
    }

    .our-tech-section>div ul li.list-item ul {
        @apply flex flex-col
    }

    .our-tech-section>div ul li.list-item ul li {
        @apply text-left md:text-center pl-7 text-lg mx-auto box-border relative leading-[1.6] before:absolute before:content-["→"] before:mr-[15px] before:text-[#333] before:top-0 before:left-0 before:text-lg
    }

    .wrap {
        @apply px-[30px] max-w-[1190px] mx-auto
    }

    .landings-title {
        @apply text-center mb-5 text-[32px] font-semibold leading-[1.2] md:text-[38px] text-white
    }

    .landings-title.blue {
        @apply text-[#0f4b81]
    }

    .landings-title.pink {
        @apply text-pixad-secondary
    }

    .landings-title.gray {
        @apply text-[#333]
    }

    .landings-text {
        @apply min-[1200px]:mb-[50px] max-w-[920px] mx-auto mb-[80px] text-center text-lg leading-normal md:text-xl
    }

    .features-section>div {
        @apply bg-[#0f4b81] pt-[50px] pb-10 md:pt-[78px] md:pb-[90px]
    }

    .features-section .features-list {
        @apply grid grid-cols-2 lg:grid-cols-4 place-items-center max-w-[550px] mx-auto lg:max-w-none
    }

    .features-section .slick-slide>div {
        @apply flex justify-center
    }

    .features-section .features-list-item {
        @apply w-full pt-[70px] px-5 pb-5 box-border h-full relative text-white max-w-[315px] md:max-w-none mx-auto md:mx-0
    }

    .features-section .features-list-item .icon-wrapper {
        @apply relative ml-[30px] mb-[60px] stroke-white stroke-2 w-[38px] h-[38px] inline-block before:content-[''] before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:h-[96px] before:w-[96px] before:absolute before:rounded-full z-10 before:-z-10
    }

    .features-section .features-list-item .icon-wrapper.blue {
        @apply before:bg-[#668edb]
    }

    .features-section .features-list-item .icon-wrapper.pink {
        @apply before:bg-pixad-secondary
    }

    .features-section .features-list-item .icon-wrapper.purple {
        @apply before:bg-[#9983b8]
    }

    .features-section .features-list-item .icon-wrapper.pink-2 {
        @apply before:bg-[#cc7894]
    }

    .features-section .features-list-item .icon-wrapper svg {
        @apply w-[38px] h-[38px] fill-transparent
    }

    .features-section .features-list-item h3 {
        @apply text-2xl font-semibold leading-[1.2] mb-5
    }

    .features-section .features-list-item p {
        @apply text-lg leading-[1.6]
    }

    .native-ad-unit-section>div {
        @apply pt-[70px] pb-20 md:pt-20
    }

    .native-ad-unit-section .ad-unit-wrapper {
        @apply max-w-[315px] block md:max-w-[730px] mx-auto md:flex md:flex-wrap md:justify-center min-[1200px]:max-w-none
    }

    .native-ad-unit-section .ad-unit-wrapper .ad-unit-list {
        @apply flex flex-wrap justify-center gap-10
    }

    .native-ad-unit-section .ad-unit-wrapper .ad-unit-list .ad-unit-item {
        @apply w-[calc(50%-40px)] min-[1200px]:w-[calc(33%-40px)]
    }

    .native-ad-unit-section .ad-unit-wrapper h3 {
        @apply text-center text-[#577e8f] font-semibold text-xl mb-5 md:text-left md:px-5
    }

    .native-ad-unit-section .ad-unit-wrapper p {
        @apply text-center text-base leading-[1.6] md:text-left md:px-5
    }

    .native-ad-unit-section .ad-unit-wrapper .ad-unit-item .image-wrapper {
        @apply mb-[100px] md:mb-5 w-full
    }

    .native-ad-unit-section .ad-unit-wrapper .ad-unit-item .image-wrapper img {
        @apply w-full h-full object-cover
    }

    .native-ad-unit-section .ad-unit-wrapper>div:last-child {
        @apply w-full text-center mt-[50px] md:mt-[70px]
    }

    .native-ad-unit-section .ad-unit-wrapper>div:last-child a {
        @apply border border-transparent rounded-[32px] bg-[#0000CC] px-9 text-white tracking-[1.4px] leading-[62px] min-h-[62px] min-w-[40px] hover:text-white hover:bg-[#000099] hover:border-[#000099] transition-colors duration-300 inline-block
    }

    .why-pixad-section>div {
        @apply pt-[60px] pb-[70px] md:pt-[90px] md:pb-[100px] bg-[#e7eff1] flex flex-col items-center
    }

    .why-pixad-section .why-list-wrapper {
        @apply max-w-[315px] min-[640px]:max-w-[640px] md:max-w-[730px] min-[1200px]:max-w-none min-[1200px]:mx-auto block md:flex flex-wrap justify-between
    }

    .why-list-wrapper .pub-why-item {
        @apply pt-[38px] mb-5 !w-[315px] relative h-[256px] px-[30px] pb-2.5 bg-[#fff] box-border md:!w-[350px] md:mb-[30px] md:pt-[46px] before:content-[''] before:w-full before:absolute before:bottom-0 before:left-0 before:right-0 before:h-1
    }

    .why-list-wrapper .pub-why-item.green {
        @apply before:bg-[#1de9b6]
    }

    .why-list-wrapper .pub-why-item.yellow {
        @apply before:bg-[#ffea00]
    }

    .why-list-wrapper .pub-why-item.green svg {
        @apply stroke-[#1de9b6]
    }

    .why-list-wrapper .pub-why-item.yellow svg {
        @apply stroke-[#ffea00]
    }

    .why-list-wrapper .pub-why-item svg {
        @apply fill-none w-[38px] h-[38px] z-10 relative mb-[38px] stroke-2
    }

    .why-list-wrapper .pub-why-item span {
        @apply text-xl font-semibold leading-[1.2]
    }

    .why-list-wrapper .why-list {
        @apply grid-cols-2 grid gap-x-10 min-[1200px]:grid-cols-3
    }

    /* SIGNUP */

    .signup-section {
        @apply min-h-screen relative
    }

    .signup-header {
        @apply absolute top-0 left-0 right-0 bottom-0 z-[-1] w-full h-auto
    }

    .signup-header img {
        @apply w-full h-full object-cover
    }

    .signup-content {
        @apply z-10 relative flex flex-wrap mx-auto justify-center w-full pt-[129px] pb-[70px]
    }

    .signup-col {
        @apply w-full m-0 lg:w-1/2 lg:max-w-[490px];

        @media (min-width: 1024px) {
            box-shadow: 0 6px 6px -3px rgba(0, 0, 0, .06), 0 10px 14px 1px rgba(0, 0, 0, .042), 0 4px 18px 3px rgba(0, 0, 0, .036);
        }
    }

    .signup-form {
        @apply py-[50px] px-[30px] bg-white max-w-[490px] mx-auto h-full flex flex-col
    }

    .signup-form h2 {
        @apply text-2xl font-semibold text-pixad-secondary
    }

    .signup-form form {
        @apply mt-2
    }

    .signup-form form .form-group {
        @apply mb-4 flex flex-col
    }

    .signup-form form label {
        @apply block text-[10px] font-semibold leading-[1.4] tracking-[0.5px] mb-1.5 uppercase text-[#333]
    }

    .signup-form form .field-wrapper {
        @apply border-b w-full leading-[1.125] text-black border-black pb-2 pt-[9px] focus-within:border-b-2 transition-all duration-100
    }

    .signup-form form .field-wrapper>* {
        @apply outline-none w-full resize-none
    }

    .signup-form .form-group.two-c {
        @apply flex-row gap-x-2
    }

    .signup-form .form-group.two-c>div {
        @apply w-1/2
    }

    .w-checkbox>label {
        @apply !flex items-center w-full
    }

    .w-checkbox label span:first-child {
        @apply w-5 h-5 inline-block
    }

    .w-checkbox label span:first-child input {
        @apply w-full h-full
    }

    .w-checkbox label span:last-child {
        @apply text-xs leading-[1.33] normal-case tracking-normal font-normal text-left inline-block h-full ml-1
    }

    .w-checkbox a {
        @apply text-[#333] text-xs border-b hover:border-b-transparent transition-all duration-200 border-b-current
    }

    .signup-info-wrapper {
        @apply bg-[#0f4b81] lg:bg-transparent lg:backdrop-blur-2xl max-w-[490px] lg:max-w-[380px]
    }

    .signup-info-wrapper>div {
        @apply max-w-[490px] h-full px-[34px] pb-14 pt-[50px] mx-auto flex flex-col justify-between
    }

    .signup-info-wrapper .top-info {
        @apply mb-[70px]
    }

    .signup-info-wrapper .top-info>h3 {
        @apply text-2xl font-semibold leading-[1.3] text-white mb-[14px]
    }

    .signup-info-wrapper .top-info>div {
        @apply mt-[50px]
    }

    .signup-info-wrapper .top-info .infos-title {
        @apply text-[42px] font-semibold leading-normal text-left text-white mb-1.5
    }

    .signup-info-wrapper .top-info .info-text {
        @apply text-base text-white leading-normal m-0 text-left
    }

    .signup-info-wrapper .top-info .info-link {
        @apply flex mt-[30px] text-pixad-secondary
    }

    .signup-info-wrapper .top-info .info-link span {
        @apply text-xs leading-[1.3] tracking-[0.6px] ml-2.5 uppercase align-middle inline-block font-semibold
    }

    .signup-info-wrapper .bottom-info {
        @apply w-full -mb-14
    }

    .signup-info-wrapper .bottom-info>div {
        @apply mt-auto mx-2.5
    }

    .signup-info-wrapper .bottom-info>div img {
        @apply w-full h-full object-cover
    }

    .cooperation-type {
        @apply text-sm font-semibold leading-[19px] mb-4 self-center
    }

    .cooperation-buttons {
        @apply flex self-center justify-center items-center bg-[#e7eff1] rounded-3xl
    }

    .cooperation-buttons button {
        @apply relative border-none cursor-pointer rounded-3xl transition-all duration-200
    }

    .cooperation-buttons button:first-child {
        @apply text-pixad-secondary
    }

    .cooperation-buttons button:last-child {
        @apply text-pixad-primary
    }

    .cooperation-buttons button:first-child:hover {
        @apply bg-pixad-secondary text-white
    }

    .cooperation-buttons button:last-child:hover {
        @apply bg-pixad-primary text-white
    }

    .cooperation-buttons button.active:first-child {
        @apply bg-pixad-secondary text-white
    }

    .cooperation-buttons button.active:last-child {
        @apply bg-pixad-primary text-white
    }

    .cooperation-buttons button.active:first-child span {
        @apply border-pixad-secondary
    }

    .cooperation-buttons button.active:last-child span {
        @apply border-pixad-primary
    }

    .cooperation-buttons button span {
        @apply px-6 tracking-[0.7px] border-4 border-[#E7EFF1] rounded-3xl inline-block
    }

    .desc {
        @apply text-center text-[#777] mb-[30px] leading-4 text-xs mt-4
    }

    .error-message {
        @apply text-red-700
    }

    .success-message {
        @apply text-green-700
    }

    .message-container {
        @apply rounded-md p-2
    }

    .message-container.error {
        @apply bg-red-700
    }

    .message-container.success {
        @apply bg-green-700
    }

    .message-container>span {
        @apply text-white
    }

    .user-agreement, .privacy-policy {
        @apply mt-[60px] lg:mt-[72px]
    }

    .privacy-policy table tr td {
        @apply border border-light-gray p-2
    }

    .privacy-policy table>tr:first-child>th {
        @apply border border-black font-bold p-2
    }

    .user-agreement>div, .privacy-policy>div {
        @apply pt-5
    }

    .user-agreement .wrap>*, .privacy-policy .wrap>* {
        @apply mb-4
    }

    .user-agreement .wrap h3, .user-agreement .wrap h4, .user-agreement .wrap h5, .user-agreement .wrap h6, .privacy-policy .wrap h3, .privacy-policy .wrap h4, .privacy-policy .wrap h5, .privacy-policy .wrap h6 {
        @apply font-semibold
    }

    .user-agreement .wrap h3, .privacy-policy .wrap h3 {
        @apply text-lg
    }

    .user-agreement .wrap h4, .privacy-policy .wrap h4 {
        @apply text-[17px]
    }

    .user-agreement .wrap h5, .privacy-policy .wrap h5 {
        @apply text-base
    }

    .user-agreement .wrap h6, .privacy-policy .wrap h6 {
        @apply text-[15px]
    }

    .user-agreement p, .privacy-policy p {
        @apply text-sm font-normal
    }

    .single-page {
        @apply relative w-full flex max-w-[1400px] mt-[72px] pt-5 mx-auto px-5 sm:px-[50px] lg:flex-row flex-col-reverse
    }

    .single-page p {
        @apply pb-4
    }
}